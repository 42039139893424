import React from 'react'
import './TermsPage.scss'

const TermsPage = () => {
  return (

    <div className="terms-wrapper">
      <div className="container">
        <h1 className="mainHeading">Terms and Conditions</h1>
        <p><strong>Last Updated: {" "}</strong>January 2025</p>

        <h2>1. Introduction</h2>
        <p>Welcome to the Pragma Once Talent Hub ("Platform"). By registering on our platform, you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before proceeding. If you do not agree with any part of these Terms, you must not register or use the Platform.</p>

        <h2>2. Data Collection and Use</h2>
        <p>By completing the registration form, you provide us with personal information, including but not limited to:</p>
        <ul>
            <li>Your first name and last name</li>
            <li>Email address</li>
            <li>Social media handles</li>
            <li>Skills, areas of interest, and years of experience</li>
        </ul>
        <p>We collect and process this data to:</p>
        <ul>
            <li>Facilitate your participation in the Talent Hub.</li>
            <li>Match your profile with potential opportunities.</li>
            <li>Communicate with you regarding updates, opportunities, and relevant information.</li>
            <li>Improve our services and enhance user experience.</li>
        </ul>

        <h2>3. Consent</h2>
        <p>By registering, you explicitly consent to the collection, processing, and storage of your personal data as described in these Terms and our <a href="https://pragmaonce.co.za/terms">Privacy Policy</a>. You have the right to withdraw your consent at any time by contacting us at <a href="mailto:info@pragmaonce.co.za">info@pragmaonce.co.za</a>.</p>

        <h2>4. Data Sharing</h2>
        <p>We may share your information with third parties, including potential employers or partners, solely for the purpose of facilitating opportunities that match your profile. We ensure that such parties adhere to applicable data protection regulations and handle your data responsibly.</p>

        <h2>5. Data Security</h2>
        <p>We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

        <h2>6. User Responsibilities</h2>
        <p>You agree to:</p>
        <ul>
            <li>Provide accurate and up-to-date information during registration.</li>
            <li>Update your profile as necessary to reflect any changes.</li>
            <li>Maintain the confidentiality of your account credentials.</li>
            <li>Be responsible for all activities that occur under your account.</li>
        </ul>

        <h2>7. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. If we make significant changes, we will notify you by email or through our Platform. Your continued use of the Platform after such modifications constitutes your acceptance of the updated Terms.</p>

        <h2>8. Contact Information</h2>
        <p>If you have any questions or concerns about these Terms or the processing of your personal data, please contact us at:</p>
        <p><strong>Email:</strong> <a href="mailto:info@pragmaonce.co.za">info@pragmaonce.co.za</a></p>

        <h2>9. Acceptance of Terms</h2>
        <p>By checking the box and submitting your registration, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</p>
    </div>
   </div>
  )
}
export default TermsPage