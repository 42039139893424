import { NewEmptyState } from "../../types/shared/EntityState";
import { SkillSet } from "../../types/shared/SkillSet";
import BaseSlice from "./baseSlice";

const entitiesName = "skillSets";

const skillSetSliceCreate = new BaseSlice<SkillSet>(entitiesName);
export const fetchAll = skillSetSliceCreate.fetchAll;
export const fetchById = skillSetSliceCreate.fetchById;
const initialState = NewEmptyState<SkillSet>();
const skillSetSlice = skillSetSliceCreate.baseSliceCreate((skillSet: SkillSet) => skillSet.id, (skillSet: SkillSet) => skillSet.title, initialState);

const reducer = skillSetSlice.reducer;
export const { sortByName, searchByName, clearSearch } = skillSetSlice.actions;
export default reducer;
    