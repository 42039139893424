import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "./redux/store";
import LoadingPage from "./pages/Archieve/LoadingPage";
import ReactGA from 'react-ga4'; 
import './App.scss'
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ProtectedAdminRoute from "./components/ProtectedRoute";
import { useDispatch } from 'react-redux';
import { setUser } from './redux/slices/usersSlice';
import { AutoScrollToTop } from "./utils/scrollToTop";
import TermsPage from "./pages/TermsPage/TermsPage";

// Lazily load components
const HomePage= lazy(() => import("./pages/HomePage"));
const ScrollToTopButton = lazy(() => import("./components/ScrollToTopButton"));
const AdminPage = lazy(() => import("./pages/Archieve/AdminPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));

const analyticsTrackingId = process.env.REACT_APP_ANALYTICS_TRACKING_ID!;

const App = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      dispatch(setUser(JSON.parse(savedUser)));
    }
  }, [dispatch]);

  useEffect(() => {
    ReactGA.initialize(analyticsTrackingId);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });
}, [])

  return (
    <BrowserRouter>
    <AutoScrollToTop />
      <Provider store={store}>
              <ToastContainer />
              <Suspense fallback={<LoadingPage />}>
              <Navbar />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={<LoginPage/>} />
                  <Route path="/terms" element={<TermsPage/>} />
                  <Route path="/admin-dashboard" element={<ProtectedAdminRoute Component={AdminPage} />} />
                  <Route path="*" element={<HomePage />} />
                </Routes>
                </Suspense>
              <ScrollToTopButton />
              <Footer />
      </Provider>
    </BrowserRouter>
  );
};

export default App;
