import { NewEmptyState } from "../../types/shared/EntityState";
import { Skill } from "../../types/shared/Skill";
import BaseSlice from "./baseSlice";

const entitiesName = "skills";

const skillSliceCreate = new BaseSlice<Skill>(entitiesName);
export const fetchAll = skillSliceCreate.fetchAll;
export const fetchByIdSkill = skillSliceCreate.fetchById;
const initialState = NewEmptyState<Skill>();
const skillSlice = skillSliceCreate.baseSliceCreate((skill: Skill) => skill.id, (skill: Skill) => skill.title, initialState);

const reducer = skillSlice.reducer;
export const { sortByName, searchByName, clearSearch } = skillSlice.actions;
export default reducer;
    