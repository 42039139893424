import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LeadState, NewLead } from "../../types/shared/Lead";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const URL = `${API_BASE_URL}/leads`;

const initialState: LeadState = {
  lead: undefined,
  loading: false,
  error: null,
};

export const createLead = createAsyncThunk(
  "leads/create",
  async (newLead: NewLead, { rejectWithValue }) => {
    try {
      const response = await axios.post(URL, newLead);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue({ message: "No response received from the server" });
      } else {
        return rejectWithValue({ message: error.message });
      }
    }
  }
);

const leadSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(createLead.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createLead.fulfilled, (state, action) => {
      state.lead = action.payload;
      state.error = null;
    });
    builder.addCase(createLead.rejected, (state, action) => {
      state.error = action.payload as string;
    });
  },
});

const leadReducer = leadSlice.reducer;
export default leadReducer;
