import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./slices/productSlice";
import categorySlice from "./slices/categorySlice";
import userReducer from "./slices/usersSlice";
import cartReducer from "./slices/cartSlice";
import checkoutReducer from "./slices/checkoutSlice";
import productImageReducer from "./slices/productImageSlice";
import orderReducer from "./slices/orderSlice";
import reviewReducer from "./slices/reviewSlice";
import vacancySlice from "./slices/vacancySlice";
import candidateSlice from "./slices/candidateSlice";
import skillSlice from "./slices/skillSlice";
import skillSetSlice from "./slices/skillSetSlice";
import leadSlice from "./slices/leadSlice";

const store = configureStore({
  reducer: {
    products: productReducer,
    categories: categorySlice,
    users: userReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    productImages: productImageReducer,
    orders: orderReducer,
    review: reviewReducer,
    vacancies: vacancySlice,
    candidates: candidateSlice,
    skills: skillSlice,
    skillSets: skillSetSlice,
  },
});

//for testing purposes
export const createNewStore = () => {
  return configureStore({
    reducer: {
      products: productReducer,
      categories: categorySlice,
      users: userReducer,
      cart: cartReducer,
      checkout: checkoutReducer,
      productImages: productImageReducer,
      orders: orderReducer,
      review: reviewReducer,
      vacancies: vacancySlice,
      candidates: candidateSlice,
      leads: leadSlice
    },
  });
};

export default store;
