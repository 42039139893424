import { QueryOptions } from "../../types/QueryOptions";
import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { NewEmptyState } from "../../types/shared/EntityState";

const API_BASE_apiUrl = process.env.REACT_APP_API_URL;

export class BaseSlice<T> {
  private _fetchAll?: ReturnType<typeof createAsyncThunk<any, QueryOptions, { rejectValue: string }>>;
  private _fetchById?: ReturnType<typeof createAsyncThunk<any, string, { rejectValue: string }>>;
  entitiesName: string = "";
  apiUrl: string = "";

  constructor(entitiesName: string) {
    this.entitiesName = entitiesName;
    this.apiUrl = `${API_BASE_apiUrl}/${entitiesName}`;
  }

  private createQueryStringBase = (options: QueryOptions) => {
    const params = new URLSearchParams();
    params.append("page", options.page.toString());
    params.append("pageSize", options.pageSize.toString());
    params.append("sortBy", options.sortBy);
    params.append("sortOrder", options.sortOrder);
    return params.toString();
  };

  public get fetchAll() {
    if (!this._fetchAll) {
      this._fetchAll = createAsyncThunk<any, QueryOptions, { rejectValue: string }>(
        `${this.entitiesName}/fetchAll`,
        async (options, { rejectWithValue }) => {
          try {
            const queryString = this.createQueryStringBase(options);
            const response = await axios.get(`${this.apiUrl}?${queryString}`);
            return response.data;
          } catch (error: any) {
            return rejectWithValue(error.response?.data || "An error occurred");
          }
        }
      );
    }
    return this._fetchAll;
  }

  public get fetchById() {
    if (!this._fetchById) {
      this._fetchById = createAsyncThunk<any, string, { rejectValue: string }>(
        `${this.entitiesName}/fetchOne`,
        async (id, { rejectWithValue }) => {
          try {
            const response = await axios.get(`${this.apiUrl}/${id}`);
            return response.data;
          } catch (error: any) {
            return rejectWithValue(error.response?.data || "An error occurred");
          }
        }
      );
    }
    return this._fetchById;
  }

  public baseSliceCreate = (
    getId: (_: T) => string,
    getName: (_: T) => string,
    initialState = NewEmptyState<T>()
  ) => {
    const entitySlice = createSlice({
      name: this.entitiesName,
      initialState,
      reducers: {
        sortByName: (state, action: PayloadAction<"asc" | "desc">) => {
          if (action.payload === "asc") {
            state.filteredEntities.sort((a, b) =>
              getName(a as T).localeCompare(getName(b as T))
            );
          } else {
            state.filteredEntities.sort((a, b) =>
              getName(b as T).localeCompare(getName(a as T))
            );
          }
        },
        searchByName: (state, action: PayloadAction<string>) => {
          const searchQuery = action.payload.toLowerCase();
          state.filteredEntities = state.entities.filter((entity) =>
            getName(entity as T).toLowerCase().includes(searchQuery)
          );
        },
        clearSearch: (state) => {
          state.filteredEntities = state.entities;
        },
      },
      extraReducers: (builder) => {
        builder.addCase(this.fetchAll!.pending, (state) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(this.fetchAll!.fulfilled, (state, action) => {
          state.loading = false;
          state.entities = action.payload.items;
          state.filteredEntities = action.payload.items;
          state.total = state.entities.length;
          state.error = null;
        });
        builder.addCase(this.fetchAll!.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });

        builder.addCase(this.fetchById!.fulfilled, (state, action: PayloadAction<T>) => {
          const entity = action.payload;
          state.entityDetailsDetails[getId(entity)] = entity as Draft<T>;
          state.loading = false;
          state.error = null;
          state.entity = entity as Draft<T>;
        });
        builder.addCase(this.fetchById!.pending, (state) => {
          return {
            ...state,
            loading: true,
            error: null,
          };
        });
        builder.addCase(this.fetchById!.rejected, (state, action) => {
          return {
            ...state,
            loading: false,
            error: action.error.message ?? "error",
          };
        });
      },
    });

    return entitySlice;
  };
}

export default BaseSlice;
