
export type EntityState<T> = {
    entities: T[];
    filteredEntities: T[];
    entity: T | null;
    entityDetailsDetails: { [key: string]: T };
    total: number;
    loading: boolean;
    error: string | null;
  };

export const NewEmptyState = <T>() : EntityState<T> => {
  const initialState: EntityState<T> = {
    entities: [],
    total: 0,
    loading: false,
    error: null,
    filteredEntities: [],
    entity: null,
    entityDetailsDetails: {}
  };
  return initialState;
}