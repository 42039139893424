import React from 'react'
import './Footer.scss'
import { NavLink } from 'react-router-dom'
import {HashLink as Link} from 'react-router-hash-link'

const Footer = () => {

    const isActiveStyle = {
        textDecoration: 'underline',
      };

  return (
    <div className="f-wrapper">
        <div className="container">
            <div className="f-container">
                <span className="title">Looking for the Best?</span>
                <p className="h-description">Get in touch: info@pragmaonce.co.za</p>
                <hr/>
                <div className="f-menu">
                    <Link  className="nav-link" to="/#what-we-do" smooth={true} >
                        <span>What we do</span>
                    </Link>
                    <Link  className="nav-link" to="/#talent-hub" smooth>
                        <span className="highlight">Talent hub</span>
                    </Link>
                    <Link  className="nav-link" to="/#how-it-works" smooth >
                        <span>How it works</span>
                    </Link>
                    <Link  className="nav-link" to="/#who-we-work-with"  smooth >
                        <span>Who we work with</span>
                    </Link>
                    <span className="nav-link"> 
                        <NavLink style={({ isActive }) => isActive ? isActiveStyle : {}} to="/terms">
                                Terms
                        </NavLink> 
                    </span>
                </div>
                <hr />
            </div>
        </div>
    </div>
  )
}

export default Footer