import { Candidate, CandidateState } from "../../types/TalentHub/Candidate";
import { QueryOptions } from "../../types/QueryOptions";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const URL = `${API_BASE_URL}/candidates`;

const initialState: CandidateState = {
  candidates: [],
  total: 0,
  loading: false,
  error: null,
  filteredCandidates: [],
  candidate: null,
  candidateDetails: {}
};

//Queries
const createQueryString = (options: QueryOptions) => {
    const params = new URLSearchParams();
    params.append("page", options.page.toString());
    params.append("pageSize", options.pageSize.toString());
    params.append("sortBy", options.sortBy);
    params.append("sortOrder", options.sortOrder);
    return params.toString();
  };
  

  //Define thunk for fetching all candidates
export const fetchAllCandidates = createAsyncThunk(
    "candidates/fetchAll",
    async (options: QueryOptions, { rejectWithValue }) => {
      try {
        const queryString = createQueryString(options);
        const response = await axios.get(`${URL}?${queryString}`);
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  
//Define thunk for fetching single product
export const fetchCandidateById = createAsyncThunk(
  "candidates/fetchOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${URL}/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


const candidateSlice = createSlice({
    name: "candidates",
    initialState,
    reducers: {
      sortCandidateByName: (state, action: PayloadAction<"asc" | "desc">) => {
        if (action.payload === "asc") {
          state.filteredCandidates.sort((a, b) => a.title.localeCompare(b.title));
        } else {
          state.filteredCandidates.sort((a, b) => b.title.localeCompare(a.title));
        }
      },
      searchCandidateByName: (state, action: PayloadAction<string>) => {
        const searchQuery = action.payload.toLowerCase();
        state.filteredCandidates = state.candidates.filter((candidate) =>
            candidate.title.toLowerCase().includes(searchQuery)
        );
      },
      clearCandidateSearch: (state) => {
        state.filteredCandidates = state.candidates;
      },
    },
    extraReducers: (builder) => {
      // #region Fetch all candidates
      builder.addCase(fetchAllCandidates.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(fetchAllCandidates.fulfilled, (state, action) => {
        state.loading = false;
        state.candidates = [...state.candidates, ...action.payload.items];
        state.filteredCandidates = [...state.candidates, ...action.payload.items];
        state.total = state.candidates.length;
        state.error = null;
      });
      builder.addCase(fetchAllCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

      // #region fetch candidate
    
    builder.addCase(
      fetchCandidateById.fulfilled,
      (state, action: PayloadAction<Candidate>) => {
        const candidate = action.payload;
        state.candidateDetails[candidate.id] = candidate;
        state.loading = false;
        state.error = null;
        state.candidate = candidate;
      }
    );
    builder.addCase(fetchCandidateById.pending, (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    });
    builder.addCase(fetchCandidateById.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error.message ?? "error",
      };
    });

    // #endregion

    },// #endregion 

    
  });
  
  const candidateReducer = candidateSlice.reducer;
  export const { sortCandidateByName, searchCandidateByName, clearCandidateSearch } =
  candidateSlice.actions;
  export default candidateReducer;
  